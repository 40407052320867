// biglio-frontend/src/components/SignInModal.js
import React, { useState, useContext } from 'react';
import { CognitoUserPool, CognitoUser, AuthenticationDetails, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { AuthContext } from '../context/AuthContext';
import awsConfig from '../aws-exports';
import '../styles/SignInModal.css';

const SignInModal = ({ isOpen, onClose }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [isSignUp, setIsSignUp] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [mfaCode, setMfaCode] = useState('');
  const [isMfaRequired, setIsMfaRequired] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false); // New state for forgot password
  const [message, setMessage] = useState('');
  const { setIsAuthenticated } = useContext(AuthContext);

  if (!isOpen) return null;

  const userPool = new CognitoUserPool({
    UserPoolId: awsConfig.userPoolId,
    ClientId: awsConfig.userPoolWebClientId,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (isSignUp) {
      const attributeList = [
        new CognitoUserAttribute({ Name: 'email', Value: email }),
      ];

      userPool.signUp(username, password, attributeList, null, (err, result) => {
        if (err) {
          setMessage(err.message || JSON.stringify(err));
          return;
        }
        setMessage('Sign up successful. Check your email for the confirmation code.');
        setIsConfirming(true);
      });
    } else {
      const user = new CognitoUser({ Username: username, Pool: userPool });
      const authDetails = new AuthenticationDetails({ Username: username, Password: password });

      user.authenticateUser(authDetails, {
        onSuccess: (result) => {
          console.log('Sign in successful:', result);
          setIsAuthenticated(true);
          onClose();
          setMessage('Sign in successful');
        },
        onFailure: (err) => {
          console.error('Sign in error:', err);
          setMessage(err.message || JSON.stringify(err));
        },
        mfaRequired: (codeDeliveryDetails) => {
          setIsMfaRequired(true);
        },
      });
    }
  };

  const handleConfirmSubmit = (event) => {
    event.preventDefault();

    const user = new CognitoUser({ Username: username, Pool: userPool });
    user.confirmRegistration(confirmationCode, true, (err, result) => {
      if (err) {
        setMessage(err.message || JSON.stringify(err));
        return;
      }
      setMessage('Confirmation successful. You can now sign in.');
      setIsConfirming(false);
      setIsSignUp(false);
    });
  };

  const handleResendCode = () => {
    const user = new CognitoUser({ Username: username, Pool: userPool });
    user.resendConfirmationCode((err, result) => {
      if (err) {
        setMessage(err.message || JSON.stringify(err));
        return;
      }
      setMessage('A new confirmation code has been sent to your email.');
    });
  };

  const handleMfaSubmit = (event) => {
    event.preventDefault();

    const user = new CognitoUser({ Username: username, Pool: userPool });
    user.sendMFACode(mfaCode, {
      onSuccess: (result) => {
        setIsAuthenticated(true);
        onClose();
        setMessage('Sign in successful');
      },
      onFailure: (err) => {
        setMessage(err.message || JSON.stringify(err));
      },
    });
  };

  const handleForgotPasswordSubmit = (event) => {
    event.preventDefault();

    const user = new CognitoUser({ Username: username, Pool: userPool });
    user.forgotPassword({
      onSuccess: (data) => {
        console.log('Code sent:', data);
        setMessage('A verification code has been sent to your email.');
        setIsConfirming(true);
      },
      onFailure: (err) => {
        console.error('Error sending code:', err);
        setMessage(err.message || JSON.stringify(err));
      },
    });
  };

  const handleResetPasswordSubmit = (event) => {
    event.preventDefault();

    const user = new CognitoUser({ Username: username, Pool: userPool });
    user.confirmPassword(confirmationCode, password, {
      onSuccess: () => {
        console.log('Password reset successfully');
        setMessage('Password reset successfully. You can now sign in.');
        setIsForgotPassword(false);
        setIsConfirming(false);
      },
      onFailure: (err) => {
        console.error('Error resetting password:', err);
        setMessage(err.message || JSON.stringify(err));
      },
    });
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}>&times;</span>
        <h2>{isSignUp ? 'Sign Up' : isForgotPassword ? 'Forgot Password' : 'Sign In'}</h2>
        {message && <div className="message">{message}</div>}
        {!isMfaRequired ? (
          !isConfirming ? (
            !isForgotPassword ? (
              <form onSubmit={handleSubmit}>
                <label htmlFor="username">Username:</label>
                <input
                  type="text"
                  id="username"
                  name="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
                <label htmlFor="password">Password:</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                {isSignUp && (
                  <>
                    <p className="password-requirements">
                      Password must be at least 8 characters long and include a mix of uppercase, lowercase, numbers, and special characters.
                    </p>
                    <label htmlFor="email">Email:</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </>
                )}
                <button type="submit">{isSignUp ? 'Sign Up' : 'Sign In'}</button>
              </form>
            ) : (
              <form onSubmit={handleForgotPasswordSubmit}>
                <label htmlFor="username">Username:</label>
                <input
                  type="text"
                  id="username"
                  name="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
                <button type="submit">Send Verification Code</button>
              </form>
            )
          ) : (
            <form onSubmit={isForgotPassword ? handleResetPasswordSubmit : handleConfirmSubmit}>
              <label htmlFor="confirmationCode">Confirmation Code:</label>
              <input
                type="text"
                id="confirmationCode"
                name="confirmationCode"
                value={confirmationCode}
                onChange={(e) => setConfirmationCode(e.target.value)}
                required
              />
              {isForgotPassword && (
                <>
                  <label htmlFor="password">New Password:</label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </>
              )}
              <button type="submit">{isForgotPassword ? 'Reset Password' : 'Confirm'}</button>
              {!isForgotPassword && <button type="button" onClick={handleResendCode}>Resend Code</button>}
            </form>
          )
        ) : (
          <form onSubmit={handleMfaSubmit}>
            <label htmlFor="mfaCode">MFA Code:</label>
            <input
              type="text"
              id="mfaCode"
              name="mfaCode"
              value={mfaCode}
              onChange={(e) => setMfaCode(e.target.value)}
              required
            />
            <button type="submit">Submit MFA Code</button>
          </form>
        )}
        {!isForgotPassword && (
          <div className="sign-up-link" onClick={() => setIsSignUp(!isSignUp)}>
            {isSignUp ? 'Already have an account? Sign In' : 'Don\'t have an account? Sign Up'}
          </div>
        )}
        {!isSignUp && !isForgotPassword && (
          <div className="sign-up-link" onClick={() => setIsForgotPassword(true)}>
            Forgot your password? Reset it here
          </div>
        )}
      </div>
    </div>
  );
};

export default SignInModal;