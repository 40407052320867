import React, { useState, useRef, useEffect } from 'react';
import { useAudioContext } from '../context/AudioContext';
import {
  FacebookShareButton,
  LinkedinShareButton,
  EmailShareButton,
  FacebookIcon,
  LinkedinIcon,
  EmailIcon
} from 'react-share';
import XIcon from './site_images/x-icon.svg.webp'; // Custom X icon
import SMSIcon from './site_images/sms-icon.png'; // Custom SMS icon
import '../styles/AudioPlayerV2.css';

const AudioPlayer = ({ audioUrl, title, category }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [error, setError] = useState(null);
  const [playbackRate, setPlaybackRate] = useState(1);
  const audioRef = useRef(null);
  const { currentlyPlayingUrl, setPlayingUrl } = useAudioContext();

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;

    const handleLoadedMetadata = () => {
      setDuration(audio.duration);
    };

    const handleTimeUpdate = () => {
      setCurrentTime(audio.currentTime);
    };

    const handleEnded = () => {
      setIsPlaying(false);
      setCurrentTime(0);
    };

    audio.addEventListener('loadedmetadata', handleLoadedMetadata);
    audio.addEventListener('timeupdate', handleTimeUpdate);
    audio.addEventListener('ended', handleEnded);

    return () => {
      audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
      audio.removeEventListener('timeupdate', handleTimeUpdate);
      audio.removeEventListener('ended', handleEnded);
    };
  }, [audioUrl]);

  useEffect(() => {
    if (currentlyPlayingUrl === audioUrl) {
      audioRef.current.play();
      setIsPlaying(true);
    } else {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  }, [currentlyPlayingUrl, audioUrl]);

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
      setPlayingUrl(null);
    } else {
      setPlayingUrl(audioUrl);
    }
  };

  const handleSeek = (e) => {
    const newTime = parseFloat(e.target.value);
    setCurrentTime(newTime);
    if (audioRef.current) {
      audioRef.current.currentTime = newTime;
    }
  };

  const handlePlaybackRateChange = (e) => {
    const newRate = parseFloat(e.target.value);
    setPlaybackRate(newRate);
    if (audioRef.current) {
      audioRef.current.playbackRate = newRate;
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const displayedTitle = title || 'Untitled Audio';
  const shareUrl = `${window.location.origin}/audio-player?audioUrl=${encodeURIComponent(audioUrl)}&topic=${encodeURIComponent(title)}&category=${encodeURIComponent(category)}`;
  const shareTitle = capitalizeWords(displayedTitle);

  return (
    <div className="audio-player-container audio-item">
      <h3 className="player-title">{capitalizeWords(displayedTitle)}</h3>
      {category && <p className="player-category">{capitalizeWords(category)}</p>}
      {audioUrl ? (
        <audio
          ref={audioRef}
          src={audioUrl}
          preload="metadata"
        />
      ) : (
        <div className="error-message">Audio URL is missing</div>
      )}
      {error && <div className="error-message">{error}</div>}
      <div className="player-content">
        <div className="controls-container">
          <div className="controls">
            <button onClick={() => audioRef.current && (audioRef.current.currentTime -= 10)} className="control-button">
              -10s
            </button>
            <button onClick={togglePlayPause} className="play-pause-button">
              {isPlaying ? '❚❚' : '▶'}
            </button>
            <button onClick={() => audioRef.current && (audioRef.current.currentTime += 10)} className="control-button">
              +10s
            </button>
          </div>
        </div>
        <div className="seek-bar-container">
          <input
            type="range"
            min="0"
            max={duration}
            value={currentTime}
            onChange={handleSeek}
            className="seek-bar"
            style={{ '--seek-value': `${(currentTime / duration) * 100}%` }}
          />
          <div className="time-display">
            <span>{formatTime(currentTime)}</span>
            <select
              value={playbackRate}
              onChange={handlePlaybackRateChange}
              className="playback-rate-select"
            >
              <option value="0.5">0.5x</option>
              <option value="1">1x</option>
              <option value="1.5">1.5x</option>
              <option value="2">2x</option>
            </select>
            <span>{formatTime(duration)}</span>
          </div>
        </div>
        <div className="share-buttons-container" style={{ marginTop: '20px' }}>
          <div className="share-buttons" style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
            <FacebookShareButton url={shareUrl} quote={shareTitle}>
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <a href={`https://x.com/intent/tweet?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(shareTitle)}`} target="_blank" rel="noopener noreferrer">
              <img src={XIcon} alt="Share on X" style={{ width: 32, height: 32, borderRadius: '50%' }} />
            </a>
            <LinkedinShareButton url={shareUrl} title={shareTitle}>
              <LinkedinIcon size={32} round />
            </LinkedinShareButton>
            <EmailShareButton url={shareUrl} subject={shareTitle} body={`Check out this audio: ${shareTitle}`}>
              <EmailIcon size={32} round />
            </EmailShareButton>
            <a href={`sms:?&body=${encodeURIComponent(shareTitle + ' ' + shareUrl)}`} target="_blank" rel="noopener noreferrer">
              <img src={SMSIcon} alt="Share via SMS" style={{ width: 32, height: 32, borderRadius: '50%' }} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;