import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AudioPlayer from './AudioPlayer';
import '../styles/HomePageV4.css';
import '../styles/AudioPlayerV2.css';

const HomePage = () => {
  const [audioFiles, setAudioFiles] = useState([]);
  const [visibleFiles, setVisibleFiles] = useState(9); // Number of files to display initially

  useEffect(() => {
    // Simulate fetching audio files
    const fetchAudioFiles = async () => {
      // Replace this with your actual data fetching logic
      const fetchedFiles = [
        { audioUrl: "https://storage-audio-file.s3.amazonaws.com/audio/What-is-biglio.mp3", topic: "What is Biglio?", category: "Business" },
        // Add more audio files here
      ];
      setAudioFiles(fetchedFiles);
    };

    fetchAudioFiles();
  }, []);

  const loadMoreFiles = () => {
    setVisibleFiles(prev => prev + 9); // Load 9 more files
  };

  return (
    <div className="home-container">
      <main className="home-content">
        <section className="features">
          <div className="feature-grid">
            <div className="feature-card">
              <h3>Instant Podcasts</h3>
              <p>From idea to content in seconds</p>
            </div>
            <div className="feature-card">
              <h3>Engaging Stories</h3>
              <p>Transform ideas into audio adventures</p>
            </div>
            <div className="feature-card">
              <h3>Historical Echoes</h3>
              <p>Relive history through audio content</p>
            </div>
          </div>
        </section>

        <section className="audio-player-section">
          {audioFiles.slice(0, visibleFiles).map((file, index) => (
            <AudioPlayer 
              key={index}
              audioUrl={file.audioUrl}
              title={file.topic}
              category={file.category}
            />
          ))}
          {visibleFiles < audioFiles.length && (
            <button onClick={loadMoreFiles} className="load-more-button">
              Load More
            </button>
          )}
        </section>

        <section className="cta">
          <Link to="/search" className="cta-button">Begin Your Audio Journey</Link>
        </section>
      </main>

      <footer className="home-footer">
        <p>Transform your ideas into audio masterpieces with Biglio.</p>
        <p>&copy; Momentum Growth Partners, LLC</p>
      </footer>
    </div>
  );
};

export default HomePage;