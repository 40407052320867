import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import '../styles/CreatePageV3.css';

const categories = [
  'Technology', 'Health', 'Science', 'Education', 'Business', 'Entertainment',
  'Sports', 'Politics', 'Travel', 'Food', 'Lifestyle', 'Finance', 'History',
  'Art', 'Music', 'Literature', 'Nature', 'Fashion', 'Culture', 'Religion',
  'Other'
];

function CreatePage() {
  const navigate = useNavigate();
  const { isAuthenticated, user, isLoading, refreshSession } = useContext(AuthContext);

  const [topic, setTopic] = useState('');
  const [category, setCategory] = useState('');
  const [contentType, setContentType] = useState('educational');
  const [length, setLength] = useState('short');
  const [specificFocus, setSpecificFocus] = useState('');
  const [selectedVoice, setSelectedVoice] = useState('Matthew');
  const [loadingGenerate, setLoadingGenerate] = useState(false);
  const [error, setError] = useState(null);
  const [contentReady, setContentReady] = useState(false);

  // New state variables for optional fields
  const [timePeriod, setTimePeriod] = useState('');
  const [geographicalFocus, setGeographicalFocus] = useState('');
  const [targetAudienceAge, setTargetAudienceAge] = useState('');
  const [contentDepth, setContentDepth] = useState('');
  const [contentStyle, setContentStyle] = useState('');

  const availableVoices = [
    { id: 'Matthew', name: 'Matthew', gender: 'Male' },
    { id: 'Ruth', name: 'Ruth', gender: 'Female' },
  ];

  const [progress, setProgress] = useState(0); // New state for progress

  useEffect(() => {
    const refreshUserSession = async () => {
      try {
        await refreshSession();
      } catch (err) {
        console.error('Error refreshing session:', err);
      }
    };

    refreshUserSession();
  }, [refreshSession]);

  const handleGenerate = async (e) => {
    e.preventDefault();
    setLoadingGenerate(true);
    setError(null);
    setContentReady(false);
    setProgress(0); // Reset progress

    if (isLoading || !isAuthenticated || !user) {
      setError('Please log in to generate audio.');
      setLoadingGenerate(false);
      return;
    }

    try {
      const userSub = user.userid;
      if (!userSub) {
        throw new Error('User ID not available. Please log in again.');
      }

      // Simulate progress
      const progressInterval = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress >= 100) {
            clearInterval(progressInterval);
            return 100;
          }
          return prevProgress + 10; // Increment progress by 10%
        });
      }, 1000); // Update every second

      // Send request to generate audio
      console.log('Sending request to generate audio...');
      const response = await fetch('https://wq0vbglp42.execute-api.us-east-1.amazonaws.com/prod/create-audio', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          topic,
          contentType,
          length,
          specificFocus,
          selectedVoice,
          category,
          userid: userSub,
          timePeriod,
          geographicalFocus,
          targetAudienceAge,
          contentDepth,
          contentStyle,
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error response body:', errorText);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('API response:', JSON.stringify(data, null, 2));

      setContentReady(true);
    } catch (err) {
      console.error('Error generating audio:', err);
      setError(`An error occurred while generating audio: ${err.message}`);
    } finally {
      setLoadingGenerate(false);
      setProgress(100); // Ensure progress is complete
    }
  };

  const handleGetSuggestions = async () => {
    setError(null);
    setLoadingGenerate(true);

    try {
      console.log('Sending request to get suggestions...');
      const response = await fetch('https://2uhtkxnckk.execute-api.us-east-1.amazonaws.com/prod/generate-suggestions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ topic, category }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error response body:', errorText);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Suggestions:', data.suggestions);

      setSpecificFocus(data.suggestions);
    } catch (err) {
      console.error('Error fetching suggestions:', err);
      setError(`An error occurred while fetching suggestions: ${err.message}`);
    } finally {
      setLoadingGenerate(false);
    }
  };

  const handleNavigateToProfile = () => {
    navigate(`/profile?userId=${user.userid}`);
  };

  return (
    <div className="create-page">
      <h1>Create Your Content</h1>
      <p>Simply fill out the form below and hit generate. In less than 1 minute, your audio content will be delivered to your profile page.</p>
      <form onSubmit={handleGenerate}>
        <div className="form-group">
          <label htmlFor="topic">Topic: <span>This will be the title of your content.</span></label>
          <input
            type="text"
            id="topic"
            value={topic}
            onChange={(e) => setTopic(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="category">Category: <span>Pick a Category.</span></label>
          <select
            id="category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            required
          >
            <option value="" disabled>Select a category</option>
            {categories.map((cat) => (
              <option key={cat} value={cat}>
                {cat}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="contentType">Content Type: <span>What type of content do you want?</span></label>
          <select
            id="contentType"
            value={contentType}
            onChange={(e) => setContentType(e.target.value)}
          >
            <option value="educational">Educational</option>
            <option value="entertainment">Entertainment</option>
            <option value="news">News</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="length">Length: <span>How long would you like it? Only short is available now.</span></label>
          <select
            id="length"
            value={length}
            onChange={(e) => setLength(e.target.value)}
          >
            <option value="short">Short</option>
            <option value="medium" disabled>Medium - Available with upgrade</option>
            <option value="long" disabled>Long - Available with upgrade</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="specificFocus">Details: <span>This is where you add the details. You have 444 characters, be as specific as you can. What you want included or not included. Is there a style you would like this in? What don't you want included?</span></label>
          <button type="button" onClick={handleGetSuggestions} className="button get-suggestions-button" disabled={loadingGenerate}>
            {loadingGenerate ? 'Fetching Suggestions...' : 'Get Suggestions'}
          </button>
          <textarea
            id="specificFocus"
            value={specificFocus}
            onChange={(e) => setSpecificFocus(e.target.value)}
            maxLength={600}
            required
            spellCheck="true"
          />
          <p>{444 - specificFocus.length} characters remaining</p>
        </div>
        <div className="form-group">
          <label htmlFor="selectedVoice">Voice:</label>
          <select
            id="selectedVoice"
            value={selectedVoice}
            onChange={(e) => setSelectedVoice(e.target.value)}
          >
            {availableVoices.map((voice) => (
              <option key={voice.id} value={voice.id}>
                {voice.name} ({voice.gender})
              </option>
            ))}
          </select>
        </div>
        {/* New optional fields */}
        <div className="form-group">
          <label htmlFor="timePeriod">Time Period: <span>(Optional)</span></label>
          <input
            type="text"
            id="timePeriod"
            value={timePeriod}
            onChange={(e) => setTimePeriod(e.target.value)}
            placeholder="e.g., 19th century, 1960s, etc."
          />
        </div>
        <div className="form-group">
          <label htmlFor="geographicalFocus">Geographical Focus: <span>(Optional)</span></label>
          <input
            type="text"
            id="geographicalFocus"
            value={geographicalFocus}
            onChange={(e) => setGeographicalFocus(e.target.value)}
            placeholder="e.g., North America, Europe, Global, etc."
          />
        </div>
        <div className="form-group">
          <label htmlFor="targetAudienceAge">Target Audience Age: <span>(Optional)</span></label>
          <select
            id="targetAudienceAge"
            value={targetAudienceAge}
            onChange={(e) => setTargetAudienceAge(e.target.value)}
          >
            <option value="">Select target audience</option>
            <option value="children">Children</option>
            <option value="teenagers">Teenagers</option>
            <option value="young adults">Young Adults</option>
            <option value="adults">Adults</option>
            <option value="seniors">Seniors</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="contentDepth">Content Depth: <span>(Optional)</span></label>
          <select
            id="contentDepth"
            value={contentDepth}
            onChange={(e) => setContentDepth(e.target.value)}
          >
            <option value="">Select content depth</option>
            <option value="overview">Overview</option>
            <option value="detailed">Detailed</option>
            <option value="in-depth">In-depth analysis</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="contentStyle">Content Style: <span>(Optional)</span></label>
          <select
            id="contentStyle"
            value={contentStyle}
            onChange={(e) => setContentStyle(e.target.value)}
          >
            <option value="">Select content style</option>
            <option value="informative">Informative</option>
            <option value="narrative">Narrative</option>
            <option value="analytical">Analytical</option>
          </select>
        </div>
        <button type="submit" className="button generate-button" disabled={loadingGenerate || isLoading}>
          {loadingGenerate ? 'Generating...' : 'Generate Audio'}
        </button>
      </form>
      {error && <p className="error">{error}</p>}
      {loadingGenerate && !contentReady && (
        <>
          <p>Generating your audio content. This will take less than 60 seconds...</p>
          <div className="progress-bar-container">
            <div className="progress-bar" style={{ width: `${progress}%` }}></div>
          </div>
        </>
      )}
      {contentReady && (
        <button className="button content-ready-button" onClick={handleNavigateToProfile}>
          YOUR FILE IS READY - CLICK HERE TO LISTEN
        </button>
      )}
    </div>
  );
}

export default CreatePage;