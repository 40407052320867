import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate
import { AuthContext } from '../context/AuthContext';
import '../styles/Navbar.css';

const Navbar = ({ onSignInClick, onSignUpClick }) => {
  const { isAuthenticated, signOut } = useContext(AuthContext);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const navigate = useNavigate(); // Initialize navigate

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const handleSignOut = () => {
    signOut();
    closeMobileMenu();
    navigate('/'); // Redirect to home page
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <Link to="/" onClick={closeMobileMenu}>Biglio</Link>
      </div>
      <div className="navbar-toggle" onClick={toggleMobileMenu}>
        <span className="navbar-toggle-icon">&#9776;</span>
      </div>
      <ul className={`navbar-links ${isMobileMenuOpen ? 'active' : ''}`}>
        <li><Link to="/" onClick={closeMobileMenu}>HOME</Link></li>
        <li><Link to="/search" onClick={closeMobileMenu}>SEARCH</Link></li>
        {isAuthenticated && (
          <>
            <li><Link to="/create" onClick={closeMobileMenu}>CREATE</Link></li>
            <li><Link to="/profile" onClick={closeMobileMenu}>MY PROFILE</Link></li>
            <li><button onClick={handleSignOut}>SIGN OUT</button></li>
          </>
        )}
        {!isAuthenticated && (
          <li><button onClick={() => { onSignInClick(); closeMobileMenu(); }}>SIGN IN</button></li>
        )}
      </ul>
    </nav>
  );
};

export default Navbar;