import React, { useEffect, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import awsConfig from '../aws-exports';
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';

const AuthHandler = () => {
  const { setIsAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    if (code) {
      const { domain, redirectSignIn } = awsConfig.oauth; // Removed unused variables responseType and scope
      const clientId = awsConfig.userPoolWebClientId;
      const redirectUri = encodeURIComponent(redirectSignIn);

      fetch(`https://${domain}/oauth2/token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `grant_type=authorization_code&client_id=${clientId}&code=${code}&redirect_uri=${redirectUri}`,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.id_token) {
            const userPool = new CognitoUserPool({
              UserPoolId: awsConfig.userPoolId,
              ClientId: awsConfig.userPoolWebClientId,
            });

            const user = new CognitoUser({
              Username: data.id_token,
              Pool: userPool,
            });

            user.getSession((err, session) => {
              if (err) {
                console.error('Error getting session:', err);
                return;
              }
              setIsAuthenticated(true);
            });
          } else {
            console.error('No id_token in response:', data);
          }
        })
        .catch((error) => {
          console.error('Error fetching token:', error);
        });
    }
  }, [setIsAuthenticated]);

  return null;
};

export default AuthHandler;