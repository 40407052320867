import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import AudioPlayer from './AudioPlayer';
import '../styles/AudioPlayerPageV2.css';
import '../styles/AudioPlayerV2.css';

const AudioPlayerPage = () => {
  const [audioFile, setAudioFile] = useState({
    audioUrl: '',
    topic: '',
    category: '',
    id: ''
  });
  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const audioUrlParam = urlParams.get('audioUrl');
    const topicParam = urlParams.get('topic');
    const categoryParam = urlParams.get('category');
    const idParam = urlParams.get('id');

    const decodedTopic = decodeURIComponent(topicParam || 'Untitled Audio');
    console.log('Decoded topic:', decodedTopic); // Debugging log

    setAudioFile({
      audioUrl: decodeURIComponent(audioUrlParam || ''),
      topic: decodedTopic,
      category: decodeURIComponent(categoryParam || ''),
      id: decodeURIComponent(idParam || '')
    });

    console.log('Audio file parameters:', { audioUrl: audioUrlParam, topic: decodedTopic, category: categoryParam, id: idParam });
  }, [location]);

  return (
    <div className="audio-player-page" style={{ padding: '20px', maxWidth: '600px', margin: '0 auto' }}>
      {audioFile.audioUrl ? (
        <AudioPlayer 
          audioUrl={audioFile.audioUrl} 
          title={audioFile.topic} // Ensure topic is passed as title
          category={audioFile.category}
        />
      ) : (
        <div className="error-message">Audio URL is missing or invalid.</div>
      )}
      <div className="additional-content">
        <p className="blurb">Create and Listen to more great content on Biglio. Sign up now!</p>
      </div>
    </div>
  );
};

export default AudioPlayerPage;