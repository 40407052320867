const awsConfig = {
    region: 'us-east-1', // Your AWS region
    userPoolId: 'us-east-1_LlJfSdyDN', // Your new User Pool ID
    userPoolWebClientId: '6514urhpq2n68nul5jd9p8h3b8', // Your new App Client ID for web
    oauth: {
      domain: 'bigger.auth.us-east-1.amazoncognito.com', // Your new Cognito domain
      scope: ['email', 'openid', 'profile'],
      redirectSignIn: 'https://biglio.com/', // Your production domain
      redirectSignOut: 'https://biglio.com/', // Your production domain
      responseType: 'code',
    },
  };
  
  export default awsConfig;