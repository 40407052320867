import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import AudioPlayer from './AudioPlayer';
import '../styles/SearchPageV2.css';
import '../styles/AudioPlayerV2.css';

const categories = [
  'All', 'Technology', 'Health', 'Science', 'Education', 'Business', 'Entertainment',
  'Sports', 'Politics', 'Travel', 'Food', 'Lifestyle', 'Finance', 'History',
  'Art', 'Music', 'Literature', 'Nature', 'Fashion', 'Culture', 'Religion',
  'Other'
];

function SearchPage() {
  const [audioFiles, setAudioFiles] = useState([]);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [visibleFiles, setVisibleFiles] = useState(10); // Number of files to show initially

  useEffect(() => {
    const fetchAudioFiles = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch('https://oovmxcx0n0.execute-api.us-east-1.amazonaws.com/v1/audio-categories?limit=100');
        if (!response.ok) {
          throw new Error(`Failed to fetch audio files: ${response.status}`);
        }
        const data = await response.json();
        const sortedFiles = data.items.map(file => ({
          ...file,
          audioUrl: file.audioUrl && !file.audioUrl.startsWith('http') ? `https://${file.audioUrl}` : file.audioUrl,
          isLoaded: true
        })).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setAudioFiles(sortedFiles);
        setFilteredFiles(sortedFiles);
      } catch (err) {
        setError(`Failed to fetch audio files. ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchAudioFiles();
  }, []);

  useEffect(() => {
    const filtered = audioFiles.filter(file => {
      const matchesSearch = file.topic.toLowerCase().includes(searchQuery.toLowerCase());
      const matchesCategory = selectedCategory === 'All' || file.category === selectedCategory;
      return matchesSearch && matchesCategory;
    });
    setFilteredFiles(filtered);
  }, [searchQuery, selectedCategory, audioFiles]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (inView && visibleFiles < filteredFiles.length) {
      setVisibleFiles((prevVisibleFiles) => prevVisibleFiles + 10);
    }
  }, [inView, filteredFiles.length, visibleFiles]);

  const renderAudioItem = (file) => (
    <div className="audio-item" key={file.id}>
      <div className="audio-player-wrapper">
        {file.audioUrl ? (
          <AudioPlayer 
            audioUrl={file.audioUrl} 
            title={file.topic || `Audio ${file.id}`}
            category={file.category}
          />
        ) : (
          <p>Audio file not available</p>
        )}
      </div>
    </div>
  );

  return (
    <div className="search-page">
      <h3 className="page-description">
        Browse biglio content and discover new topics and perspectives.
      </h3>
      <section className="search-bar">
        <label htmlFor="search-input" className="search-label">
          Search for content:
        </label>
        <input
          id="search-input"
          type="text"
          placeholder="Search audio files..."
          value={searchQuery}
          onChange={handleSearchChange}
          spellCheck="true"
        />
      </section>
      <section className="category-filters">
        <label htmlFor="category-select" className="category-label">
          Filter by category:
        </label>
        <select 
          id="category-select"
          className="category-dropdown" 
          value={selectedCategory} 
          onChange={handleCategoryChange}
        >
          {categories.map((cat) => (
            <option key={cat} value={cat}>
              {cat}
            </option>
          ))}
        </select>
      </section>
      {error && <p className="error-message">{error}</p>}
      {loading ? (
        <div className="loading-spinner">Loading...</div>
      ) : (
        <div className="audio-library">
          {filteredFiles.slice(0, visibleFiles).map(renderAudioItem)}
          <div ref={ref}></div>
        </div>
      )}
    </div>
  );
}

export default SearchPage;