import React, { createContext, useContext, useState } from 'react';

const AudioContext = createContext();

const AudioProvider = ({ children }) => {
  const [currentlyPlayingUrl, setCurrentlyPlayingUrl] = useState(null);

  const setPlayingUrl = (url) => {
    setCurrentlyPlayingUrl(url);
  };

  return (
    <AudioContext.Provider value={{ currentlyPlayingUrl, setPlayingUrl }}>
      {children}
    </AudioContext.Provider>
  );
};

const useAudioContext = () => {
  const context = useContext(AudioContext);
  if (!context) {
    throw new Error('useAudioContext must be used within an AudioProvider');
  }
  return context;
};

export { AudioProvider, useAudioContext };