import React, { useState } from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import UserPool from '../utils/UserPool';
import '../styles/ForgotPassword.css';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [step, setStep] = useState(1);
  const [error, setError] = useState(null);

  const handleSendCode = (e) => {
    e.preventDefault();
    const user = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });

    user.forgotPassword({
      onSuccess: (data) => {
        console.log('Code sent:', data);
        setStep(2);
      },
      onFailure: (err) => {
        console.error('Error sending code:', err);
        setError(mapErrorMessage(err.message || JSON.stringify(err)));
      },
    });
  };

  const handleResetPassword = (e) => {
    e.preventDefault();
    const user = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });

    user.confirmPassword(code, newPassword, {
      onSuccess: () => {
        console.log('Password reset successfully');
        setStep(3);
      },
      onFailure: (err) => {
        console.error('Error resetting password:', err);
        setError(mapErrorMessage(err.message || JSON.stringify(err)));
      },
    });
  };

  const mapErrorMessage = (message) => {
    if (message.includes('Value at \'username\' failed to satisfy constraint')) {
      return 'The username must contain only letters, numbers, and special characters.';
    }
    // Add more mappings as needed
    return message;
  };

  return (
    <div className="forgot-password-page">
      <div className="forgot-password-content">
        <h1>Forgot Password</h1>
        {step === 1 && (
          <form onSubmit={handleSendCode}>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <button type="submit">Send Code</button>
          </form>
        )}
        {step === 2 && (
          <form onSubmit={handleResetPassword}>
            <div className="form-group">
              <label htmlFor="code">Verification Code:</label>
              <input
                type="text"
                id="code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="newPassword">New Password:</label>
              <input
                type="password"
                id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit">Reset Password</button>
          </form>
        )}
        {step === 3 && <p className="message">Password reset successfully. You can now sign in with your new password.</p>}
        {error && <p className="error">{error}</p>}
      </div>
    </div>
  );
}

export default ForgotPassword;