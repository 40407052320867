import React, { useState, useContext, useEffect, useCallback } from 'react';
import { AuthContext } from '../context/AuthContext';
import AudioPlayer from './AudioPlayer';
import '../styles/AudioPlayerV2.css';
import '../styles/MyProfile.css';
import profileImage from '../components/UserProfilePics/biglio-b.png';

const MyProfile = () => {
  const { isAuthenticated, user, isLoading, refreshSession } = useContext(AuthContext);
  const [audioFiles, setAudioFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userSub, setUserSub] = useState(null);

  useEffect(() => {
    const refreshUserSession = async () => {
      try {
        await refreshSession();
      } catch (err) {
        console.error('Error refreshing session:', err);
        setError('Failed to refresh session. Please sign in again.');
      }
    };

    refreshUserSession();
  }, [refreshSession]);

  useEffect(() => {
    if (user && user.idToken) {
      console.log('User object:', user);
      console.log('idToken:', user.idToken);
      try {
        let sub;
        if (typeof user.idToken === 'string') {
          const payload = JSON.parse(atob(user.idToken.split('.')[1]));
          sub = payload.sub;
        } else if (user.idToken.payload && user.idToken.payload.sub) {
          sub = user.idToken.payload.sub;
        } else if (user.userid) {
          sub = user.userid;
        } else {
          throw new Error('Unable to extract user ID');
        }
        console.log('Extracted user ID:', sub);
        setUserSub(sub);
      } catch (err) {
        console.error('Error processing user information:', err);
        setError('Failed to get user information. Please sign in again.');
      }
    } else {
      console.log('User or idToken not available');
    }
  }, [user]);

  const fetchUserAudioFiles = useCallback(async () => {
    console.log('Fetching audio files. isAuthenticated:', isAuthenticated, 'userSub:', userSub);
    if (isAuthenticated && userSub) {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch(
          `https://r2qphapv7a.execute-api.us-east-1.amazonaws.com/prod/audio-files-for-my-profile?userid=${userSub}`
        );
        console.log('API response status:', response.status);
        if (!response.ok) {
          throw new Error(`Failed to fetch audio files: ${response.status}`);
        }
        const data = await response.json();
        console.log('Fetched audio files:', data);
        const sortedFiles = data.map(file => ({ ...file, isLoaded: true }))
                               .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setAudioFiles(sortedFiles);
      } catch (error) {
        console.error('Error fetching user audio files:', error);
        setError(`Failed to fetch audio files. ${error.message}`);
      } finally {
        setLoading(false);
      }
    }
  }, [isAuthenticated, userSub]);

  useEffect(() => {
    fetchUserAudioFiles();
  }, [fetchUserAudioFiles]);

  const renderAudioItem = (file) => {
    if (!file.isLoaded) {
      return <div className="audio-item loading">Loading audio...</div>;
    }
    return (
      <div className="audio-item">
        <div className="audio-player-wrapper">
          <AudioPlayer 
            audioUrl={file.audioUrl} 
            title={file.topic}
            category={file.category}
          />
        </div>
      </div>
    );
  };

  if (isLoading) {
    return <div>Loading user information...</div>;
  }

  if (!isAuthenticated || !userSub) {
    return <div>Please log in to view your profile.</div>;
  }

  return (
    <div className="profile-container">
      <header className="profile-header">
        <div className="profile-image-container">
          <img src={profileImage} alt="Profile" className="profile-image" />
        </div>
        <h2 className="profile-username">{user.username}</h2>
      </header>
      <main>
        <section className="profile-info">
          <p>{user.email}</p>
        </section>
        <section>
          {loading && <div className="loading-spinner">Loading...</div>}
          {error && <p className="error-message">{error}</p>}
          {!loading && !error && audioFiles.length === 0 && <p>No audio files found.</p>}
          <div className="audio-library">
            {!loading && audioFiles.map((file) => (
              <React.Fragment key={file.id}>
                {renderAudioItem(file)}
              </React.Fragment>
            ))}
          </div>
        </section>
      </main>
    </div>
  );
};

export default MyProfile;