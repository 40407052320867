import React, { createContext, useState, useEffect, useCallback } from 'react';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import awsConfig from '../aws-exports';

const AuthContext = createContext();

const userPool = new CognitoUserPool({
  UserPoolId: awsConfig.userPoolId,
  ClientId: awsConfig.userPoolWebClientId,
});

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [lastRefresh, setLastRefresh] = useState(0);

  const refreshSession = useCallback(async () => {
    const currentTime = Date.now();
    if (currentTime - lastRefresh < 60000) { // 1 minute
      console.log('Skipping refresh, too soon since last refresh');
      return;
    }

    return new Promise((resolve, reject) => {
      const cognitoUser = userPool.getCurrentUser();
      if (cognitoUser) {
        cognitoUser.getSession((err, session) => {
          if (err) {
            console.error('Error refreshing session:', err);
            setIsAuthenticated(false);
            setUser(null);
            setIsLoading(false);
            reject(err);
          } else {
            console.log('Session refreshed');
            setIsAuthenticated(true);
            setUser({
              userid: session.getIdToken().payload.sub,
              username: cognitoUser.getUsername(),
              idToken: session.getIdToken(),
              accessToken: session.getAccessToken(),
              refreshToken: session.getRefreshToken(),
            });
            setIsLoading(false);
            setLastRefresh(currentTime);
            resolve(session);
          }
        });
      } else {
        setIsLoading(false);
        resolve(null);
      }
    });
  }, [lastRefresh]);

  useEffect(() => {
    const initializeAuth = async () => {
      try {
        await refreshSession();
      } catch (error) {
        console.error('Error initializing authentication:', error);
      }
    };

    initializeAuth();
  }, [refreshSession]);

  const signOut = () => {
    const currentUser = userPool.getCurrentUser();
    if (currentUser) {
      currentUser.signOut();
    }
    setIsAuthenticated(false);
    setUser(null);
  };

  const getSub = useCallback(() => {
    return user ? user.userid : null;
  }, [user]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        isLoading,
        setIsAuthenticated,
        refreshSession,
        getSub,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };